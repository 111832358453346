.view-selector-container {
    position: relative;
    height: 100%;
    float: right;
    transition: opacity 0.3s;
}

.view-selector-button-dashwise {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    color: var(--purplenavy);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-selector-button-finsights {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    color: var(--fnblack);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-selector-button-dashwise>img {
    margin-right: 1rem;
}

.view-selector-button-finsights>img {
    margin-right: 1rem;
}

.view-selector-views-dashwise {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 18rem;
    top: 4rem;
    left: -50%;
    background: var(--lighterwhite);
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 999;
}

.view-selector-views-finsights {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 18rem;
    top: 4rem;
    left: -50%;
    background: var(--fnbeigemeddark);
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 999;
}

.view-selector-option {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    color: var(--darkergray);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-selector-option>svg {
    margin-right: 0.5rem;
}

.view-selector-options-divider {
    height: 1rem;
    width: 1px;
    background-color: var(--gray);
    margin: 0 1rem;
    align-self: center;
}

.active {
    color: var(--purplenavy);
}

