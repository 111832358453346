.list-item-wrapper {
    width: 100%;
    font-size: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
}

.none-value {
    color: var(--mediumgray);
    font-style: italic;
}

.list-item-color {
    float: left;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 8px;
}

.list-item-label {
    float: left;
    width: 60%;
    font-weight: 500;
}

.list-item-number {
    float: right;
    font-weight: bold;
    text-align: right;
    padding-right: 5%;
    width: 35%;
}