.clients-table-cell-0 > img {
    margin-right: 1rem;
}

.clients-table-row-btn, .client-table-cell-save {
    padding: 0px 32px;
    border: none;
    border-radius: 20px;
    color: var(--purplenavy);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 1rem;
    height: 30px;
    width: 180px;
    background-color: var(--graycloudy);
}

.clients-table-row-btn:disabled {
    cursor: default;
    background-color: var(--graycloudy);
}

.client-table-row-loading {
    animation: rotate 2s linear infinite;
    margin-right: 0.6rem;
}

.clients-table-cell-menu {
    position: absolute;
    left: 0;
    cursor: pointer;
}

.client-table-row-menu {
    position: absolute;
    left: 20px;
    top: 2px;
    background: var(--lighterwhite);
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    z-index: 100;
}

.client-table-row-menu-option {
    padding: 10px 20px;
    color: var(--purplenavy);
    font-weight: 500;
    cursor: pointer;
}

.client-table-row-menu-option:hover {
    background: var(--lightturquoise);
}

.client-table-cell-input {
    border: none;
    outline: none;
}

.input-url {
    width: 100%;
}

.client-table-cell-save {
    width: 100px;
}

.notification {
    box-shadow: none !important;
    border-radius: 8px;
}

