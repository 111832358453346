:root {
    --nav-width: 312px;
    --header-width: calc(100% - var(--nav-width));
    --header-height: 70px;
    --main-width: var(--header-width);
    --main-height: calc(100% - var(--header-height));
    --main-left-width: 75%;
    --main-right-width: calc(100% - var(--main-left-width));
    --padding: 12px;
}


main {
    position: fixed;
    left: var(--nav-width);
    top: 0px;
    width: var(--main-width);
    height: 100%;
    padding: var(--padding);
    user-select: none;
}

.main-dashwise {
    background-color: var(--lightergray);
    color: var(--purplenavy);
}

.main-finsights{
    color: var(--fnblack);
}

header {
    width: 100%;
    height: var(--header-height);
    position: relative;
}

.title-dashwise {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    height: 100%;
    float: left;
    padding: 12px;
}

.title-finsights {
    display: flex;
    justify-content: center;
    font-size: 34px;
    font-weight: 400;
    height: 100%;
    float: left;
    padding: 12px;
    font-style: normal;
    color: var(--fnblack);
    font-family: 'Vesper Libre', serif;
}

.content {
    width: 100%;
    height: var(--main-height);
}

section {
    float: left;
    height: 100%;
}

section.primary {
    width: var(--main-left-width);
    height: 100%;
}

section.secondary {
    width: var(--main-right-width);
    height: 100%;
}
