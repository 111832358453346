.tooltip-wrapper {
    width: fit-content;
    font-size: 12px;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.tooltip-legend-wrapper {
    margin-top: 0.5rem;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
}

.tooltip-actual {
    display: flex;
    justify-content: space-between;
    margin: 0.25rem 0;
    width: 12rem;
}

.tooltip-budget {
    display: grid;
    grid-template-columns: auto;
    margin: 0.25rem 0;
}

.tooltip-item-label-dashwise {
    display: flex;
    align-items: center;
}

.tooltip-item-label-finsights {
    font-family: 'DM Sans', sans-serif;
    display: flex;
    align-items: center;
}

.tooltip-item-label-dashwise > span {
    font-weight: 500;
    color: var(--purplenavy);
}

.tooltip-item-label-finsights>span {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    color: var(--fnblack);
}

.tooltip-value-dashwise {
    font-weight: bold;
    color: var(--purplenavy);
    text-align: right;
    min-width: 2.5rem;
}

.tooltip-value-finsights {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    color: var(--fnblack);
    text-align: right;
    min-width: 2.5rem;
}

.tooltip-column-name-dashwise {
    font-weight: bold;
    color: var(--darkergray);
}

.tooltip-column-name-finsights {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: bold !important;
}

.tooltip-color {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.tooltip-label-dashwise {
    text-align: center;
    font-weight: bold;
}

.tooltip-label-finsights {
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-weight: bold;
}

.tooltip-right-column {
    margin-left: 1rem;
}