.cashflow-scorecard-tile {
    width: 100%;
    height: fit-content !important;
    padding: 20px;
    ;
    box-shadow: 0px 4px 20px 0px rgba(58, 73, 125, 0.14);
}

.cashflow-scorecard-number-dashwise {
    color: var(--purplenavy);
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 5px;
}

.cashflow-scorecard-number-finsights {
    color: var(--fnfontlight);
    font-family: 'DM Sans', sans-serif !important;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.cashflow-scorecard-sign-dashwise {
    color: var(--purplenavy);
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 0.25rem;
}

.cashflow-scorecard-sign-finsights {
    color: var(--fnfontlight);
    font-family: 'DM Sans', sans-serif !important;
    font-size: 1.5rem;
    font-weight: 500;
    margin-right: 0.25rem;
}

.cashflow-scorecard-label-dashwise {
    background-color: var(--purplenavy);
    color: var(--lighterwhite);
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1rem;
    font-weight: 700;
    width: 134px;
    height: 24px;
    border-radius: 10px;
    text-align: center;
}

.cashflow-scorecard-label-finsights {
    background-color: #C3AA8C;
    color: var(--lighterwhite);
    font-family: 'DM Sans', sans-serif !important;
    font-size: 1rem;
    font-weight: 700;
    width: 134px;
    height: 24px;
    border-radius: 10px;
    text-align: center;
}

.cashflow-var-dashwise {
    color: var(--purplenavy);
}

.cashflow-var-finsights {
    color: #464646;
}