.client-selector {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--whitegray);
    border-radius: 20px;
    padding: 4px 12px;
    min-width: 250px;
    cursor: pointer;
}


.client-selector-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--lighterwhite);
    left: 0;
    top: 45px;
    z-index: 100;
    width: 250px;
    height: 200px;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    color: var(--purplenavy);
    font-weight: 500;
}

.client-selector-option {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.client-selector-option:hover {
    background: var(--lightturquoise);
}

.client-selector-option > img {
    margin-right: 0.5rem;
}