.view-selector-views-view-option-dashwise {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: var(--lighterwhite);
    transition: background 0.2s linear;
}

.view-selector-views-view-option-finsights {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: var(--fnbeigemeddark);
    transition: background 0.2s linear;
}

.view-selector-views-view-option-span-dashwise {
    color: var(--darkergray);
    font-size: 14px;
}

.view-selector-views-view-option-span-finsights {
    color: var(--fnblacklight);
    font-size: 14px;
}

.view-selector-views-view-option-span-dashwise>svg {
    vertical-align: top;
    margin-right: 0.5rem;
}

.view-selector-views-view-option-span-finsights>svg {
    vertical-align: top;
    margin-right: 0.5rem;
}

.view-option-span-active-dashwise {
    color: var(--purplenavy);
    font-weight: 700;
}

.view-option-span-active-finsights {
    color: var(--fnblack);
    font-weight: 700;
}

.view-option-active-dashwise {
    background: var(--lightturquoise);
}

.view-option-active-finsights {
    background: var(--viewselectoroptionactive);
}