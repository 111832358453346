.treeview-arrow {
    margin-left: 0.75rem;
    vertical-align: middle;
    cursor: pointer;
    padding: .25rem;
    transition: transform 300ms ease-in-out;
}

.down {
    transform: rotate(180deg);
    transition: transform 300ms ease-in-out;
}