select {
    border: none;
}

select:focus {
    outline: none;
}

.users-table-wrapper {
    position: relative;
    padding: 1.5rem;
    height: 100%;
    width: 100%;
}

.users-table-container {
    border: 1px solid var(--graycloudy);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.users-table-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* border-top: 1px solid var(--graycloudy); */
}

.users-table-row-new {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--graycloudy);
}

.user-table-input-new {
    border: none;
    outline: none;
}

.users-table-row-add {
    position: sticky;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--graycloudy);
    background-color: #fff;
    cursor: pointer;
}

/* .clients-table-add {
    padding: 1rem 1.25rem;
} */

/* .clients-table-add>img {
    margin-right: 1rem;
} */

.users-table-row-column {
    font-weight: 700;
    background-color: var(--lighterwhite);
}

.users-table-cell-0,
.users-table-cell-1,
.users-table-cell-2,
.users-table-cell-3,
.users-table-cell-4,
.users-table-cell-5 {
    padding: 1rem 1.25rem;
}

.users-table-cell-0 {
    position: relative;
    display: flex;
    align-items: center;
    width: 200px;
    min-width: 200px;
    border-right: 1px solid var(--graycloudy);
    border-top: 1px solid var(--graycloudy);
}

.users-table-cell-1 {
    display: flex;
    align-items: center;
    width: 80px;
    min-width: 80px;
    border-right: 1px solid var(--graycloudy);
    border-top: 1px solid var(--graycloudy);
}

.users-table-cell-2 {
    display: flex;
    align-items: center;
    width: 280px;
    min-width: 280px;
    border-right: 1px solid var(--graycloudy);
    border-top: 1px solid var(--graycloudy);
}

.users-table-cell-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    min-width: 150px;
    border-right: 1px solid var(--graycloudy);
    border-top: 1px solid var(--graycloudy);
}

.users-table-cell-4 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid var(--graycloudy);
    border-top: 1px solid var(--graycloudy);
    width: 300px;
    min-width: 300px;
}

.users-table-cell-5 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--graycloudy);
    width: 300px;
    min-width: 300px;
}


