.checkbox-container {
    display: block;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-checkmark-dashwise, .checkbox-checkmark-finsights {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid var(--mediumgray);
    border-radius: 4px;
    background-color: var(--white);
}

.checkbox-container input:checked~.checkbox-checkmark-dashwise {
    background-color: var(--mediumturquoise);
    border: 2px solid var(--mediumturquoise);
}

.checkbox-container input:checked~.checkbox-checkmark-finsights {
    background-color: var(--fnorange);
    border: 2px solid var(--fnorange);
}

.checkbox-checkmark-dashwise:after, .checkbox-checkmark-finsights:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkbox-checkmark-dashwise:after, .checkbox-container input:checked~.checkbox-checkmark-finsights:after {
    display: block;
}

.checkbox-container .checkbox-checkmark-dashwise:after, .checkbox-container .checkbox-checkmark-finsights:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid var(--white);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-logo {
    margin-right: 0.5rem;
}