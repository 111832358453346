.node-container {
    display: grid;
}

.liab {
    border-bottom: 1px solid #EBEDF2;
}



