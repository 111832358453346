.collapse-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background-color: white;
    cursor: pointer;
    border-radius: 5px 0px 0px 5px;
    box-shadow: 0px 4px 20px 0px rgba(156, 129, 104, 0.10);
}

.colapse-arrow {
    margin-left: 0.75rem;
    vertical-align: middle;
    cursor: pointer;
    padding: .25rem;
    box-shadow: 0px 10px 10px 0px #3A497D33;
}

.right {
    transform: rotate(180deg);
    transition: transform 300ms ease-in-out;
}

.left {
    transform: rotate(360deg);
    transition: transform 300ms ease-in-out;
}