.cashflow-budget-selector-option-dashwise {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--lighterwhite);
    transition: background 0.2s linear;
    font-weight: 500;
    color: var(--purplenavy);
    cursor: auto;
}

.cashflow-budget-selector-option-finsights {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--fnbeigemeddark);
    transition: background 0.2s linear;
    font-weight: 500;
    color: var(--fnblack);
    cursor: auto;
}

.cashflow--budget-option-active-dashwise {
    background: var(--lightturquoise);
    cursor: pointer;
}

.cashflow--budget-option-active-finsights {
    background: var(--viewselectoroptionactive);
    cursor: pointer;
}

.cashflow-budget-selector-option-span {
    color: var(--darkergray);
}

.cashflow-budget-option-span-active {
    color: var(--purplenavy)
}