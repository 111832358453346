.refresh-button-container {
    height: 100%;
    width: 20%;
    float: right;
}

.refresh-button {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--mediumturquoise);
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.refresh-button-text-dashwise {
    color: var(--mediumturquoise)
}

.refresh-button-text-finsights {
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblack)
}