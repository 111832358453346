.nav-menu-dropdown-item-purplenavy-dashwise {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--purplenavy);
    cursor: pointer;
}

.nav-menu-dropdown-item-purplenavy-finsights {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblacklight);
    cursor: pointer;
}

.nav-menu-dropdown-item-mediumturquoise-dashwise {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--purplenavy);
    cursor: pointer;
}

.nav-menu-dropdown-item-mediumturquoise-finsights {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblacklight);
    cursor: pointer;
}

.nav-menu-dropdown-item-orange-dashwise {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--purplenavy);
    cursor: pointer;
}

.nav-menu-dropdown-item-orange-finsights {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblacklight);
    cursor: pointer;
}

.nav-menu-dropdown-item:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-purplenavy-dashwise:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-purplenavy-finsights:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-mediumturquoise-dashwise:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-mediumturquoise-finsights:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-orange-dashwise:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-orange-finsights:hover {
    font-weight: 700;
}

.nav-menu-dropdown-item-active-dashwise {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--mediumturquoise);
    cursor: pointer;
}

.nav-menu-dropdown-item-active-finsights {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 700;
    font-size: 0.875rem;
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnorange);
    cursor: pointer;
}

.nav-menu-dropdown-item-active-orange-dashwise {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--mediumturquoise);
    cursor: pointer;
}

.nav-menu-dropdown-item-active-orange-finsights {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--fnorange);
    cursor: pointer;
}

.nav-menu-dropdown-item-mediumturquoise {
    color: var(--mediumturquoise);
}

.nav-menu-dropdown-item-purplenavy {
    color: var(--purplenavy);
}

.nav-menu-dropdown-item-orange {
    color: var(--orange);
}

.nav-menu-dropdown-item-check {
    margin-right: 2rem;
    margin-left: 1rem;
}
