.dashboard-container-dashwise {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(254deg, #FEFFFF 0%, #F3F6F9 100%);
}

.dashboard-container-finsights {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(254deg, #FBFBF6 0%, #F4F1E9 100%) !important;
}