:root {
    --lightblue: #257597;
}

.error{
    width: 100%;
    height: 100vh;
    padding: 5%;
    font-family: 'Poppins';
}
.error-container {
    margin: auto;
    border-radius: 20px;
    width: 70%;
    padding: 50px;
    text-align: center;
    box-shadow: var(--black1) 0px 5px 15px;
    background: var(--white);
}
.small-logo img{
    height: 300px;
    width: 300px;
}
.oops-text {
    font-size: var(--fontsize140);
    font-weight: 800;
    color: var(--lightblue);
}
.error-code {
    font-size: var(--fontsize48);
    font-weight: 700;
    margin-bottom: 20px;
}
.error-text {
    text-align: center;
    font-size: var(--fontsize22);
    font-weight: 500;
}
.home-button {
    padding-top: 40px;
}
.home-button button{
    background-color: var(--lightblue);
    height: 50px;
    width: 100px;
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontsize22);
    font-weight: 500;
}
.login-section .error-code {
    font-size: var(--fontsize);
    font-weight: 700;
    margin-top: 20px;
}
.login-section .error-text {
    font-size: var(--fontsize18);
    font-weight: 500;
}

@media screen and (max-width: 1400px) and (min-width: 901px){
    .error-container {
        padding: 30px;
    }
    .small-logo img{
        height: 250px;
        width: 250px;
    }
}

@media screen and (max-width: 900px) and (min-width: 421px){
    :root{
        --lightblue: #237C9B;
        --white: #FFFFFF;
    }

    .container-fluid{
        --fontsize: 32px;
        --fontsizetext: 22px;
        --fontsizeoops: 120px;
        --fontsizecode: 26px;
        --fontsizeerrortext: 18px;
    }

    .error-container {
        padding: 30px 20px;
    }
    .small-logo img{
        height: 200px;
        width: 200px;
    }

    .error-code {
        font-size: var(--fontsize);
        font-weight: 600;
        margin-top: 20px;
    }
    .error-text {
        font-size: var(--fontsizetext);
        font-weight: 400;
    }

    .oops-text {
        color: var(--white);
        font-size: var(--fontsizeoops);
    }

    .login-section .error-code {
        font-size: var(--fontsizecode);
        font-weight: 700;
        margin-top: 20px;
        color: var(--white);
    }
    .login-section .error-text {
        font-size: var(--fontsizeerrortext);
        font-weight: 500;
        color: var(--white);
    }
    .login-section .home-button button {
        background-color: var(--white);
        color: var(--lightblue);
        margin-top: 30px;
    }
}

@media screen and (max-width: 420px) {
    :root{
        --white: #FFFFFF;
        --lightblue: #237C9B;
    }

    .container-fluid{
        --fontsize: 22px;
        --fontsizetext:14px;
        --fontsizebutton: 16px;
        --fontsizeoops:100px;
    }

    .error-container {
        padding: 30px 20px;
    }
    .small-logo img{
        height: 100px;
        width: 100px;
    }

    .error-code {
        font-size: var(--fontsize);
        font-weight: 600;
        margin-top: 20px;
    }
    .error-text {
        font-size: var(--fontsizetext);
        font-weight: 400;
    }
    .home-button {
        padding: 0;
    }

    .home-button button {
        height: 30px;
        width: 70px;
        font-size: var(--fontsizebutton);
        font-weight: 500;
        margin-top: 20px;
    }
    .oops-text {
        color: var(--white);
        font-size: var(--fontsizeoops);
    }

    .login-section .error-code {
        font-size: var(--fontsize);
        font-weight: 700;
        margin-top: 20px;
        color: var(--white);
    }
    .login-section .error-text {
        font-size: var(--fontsizebutton);
        font-weight: 500;
        color: var(--white);
    }
    .login-section .home-button button {
        background-color: var(--white);
        color: var(--lightblue);
        margin-top: 40px;
    }
}