.analyze-btn-container {
    display: inline-block;
    vertical-align: middle;
}

.analyze-btn-dashwise {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2rem;
    border: none;
    border-radius: 20px;
    padding-right: 0.75rem;
    font-size: 0.6rem;
    font-weight: 700;
    font-style: normal !important;
    cursor: pointer;
    transition: opacity 0.2s linear;
    box-sizing: border-box;
    background: var(--yellowsunshine);
    color: var(--purplenavy);
}

.analyze-btn-finsights {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2rem;
    border: none;
    border-radius: 20px;
    padding-right: 0.75rem;
    font-size: 0.6rem;
    font-weight: 700;
    cursor: pointer;
    transition: opacity 0.2s linear;
    box-sizing: border-box;
    background: var(--fnbeigemeddark);
    color: var(--fnfontlight);
    font-style: normal !important;
    font-family: 'DM Sans', sans-serif;
}


.analyze-btn-icon-container-dashwise {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 24px;
    border-radius: 42px;
    background-color: var(--fnfontlight);
    box-sizing: border-box;
    margin-right: 0.5rem;
    transition: opacity 0.2s linear;
}

.analyze-btn-icon-container-finsights {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 24px;
    border-radius: 42px;
    background-color: var(--fnfontlight);
    box-sizing: border-box;
    margin-right: 0.5rem;
    transition: opacity 0.2s linear;
}