:root {
  --seagreen: #0eb6b9;
  --transparantgrey: rgba(235, 240, 240, 0.336);
}

.loader-background {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--transparantgrey);
}

.dna-wrapper circle {
  fill: var(--seagreen) !important;
}