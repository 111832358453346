.user-division-selector-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--lighterwhite);
    left: 20px;
    top: 55px;
    z-index: 100;
    width: 250px;
    height: fit-content;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    color: var(--purplenavy);
    font-weight: 500;
}

.user-clients-save-container {
    position: absolute;
    display: flex;
    padding: 1rem;
    padding-right: 2rem;
    background-color: var(--lighterwhite);
    z-index: 1000;
    top: -15px;
    left: 250px;
}

