.nav-menu {
    top: unset;
    left: unset;
    width: unset;
    border-radius: unset;
    box-shadow: unset;
    padding: unset;
    margin-top: 1.875rem;
    background: var(--lightturquoise);
    
}

.nav-menu-transition {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.nav-menu-label {
    position: relative;
    color: var(--mediumturquoise);
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
}

.nav-menu-label > span:first-of-type {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: var(--mediumturquoise);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.nav-menu-label>span:nth-of-type(2) {
    position: absolute;
    left: 1.5rem;
}

.nav-menu-label > span:nth-of-type(3) {
    margin-left: 2rem;
}

.nav-menu-label > span:nth-of-type(4) {
    display: inline-block;
    position: absolute;
    right: 1rem;
    cursor: pointer;
}

.nav-menu-title {
    font-weight: 700;
}


