.user-table-cell-input {
    border: none;
    outline: none;
}

.users-table-cell-menu {
    position: absolute;
    left: 0;
    cursor: pointer;
}

.input-email {
    width: 100%;
}

.user-table-save-container {
    position: absolute;
    display: flex;
    padding: 1rem;
    padding-right: 2rem;
    background-color: var(--lighterwhite);
    z-index: 1000;
    top: 0px;
    left: 0;
}

.client-table-cell-saving, .user-table-save-container-saving {
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 4px 12px;
    min-width: 250px;
    background-color: var(--whitegray);
    left: 16px;
}

.user-table-save-container-saving {
    justify-content: flex-start;
    background-color: var(--lighterwhite);
}

.user-table-row-loading {
    animation: rotate 2s linear infinite;
}