.deepdive-panel-header-dashwise {
  padding: 16px 32px;
}

.deepdive-panel-header-finsights {
  font-family: 'DM Sans', sans-serif !important;
  padding: 16px 32px;
}

.deepdive-panel-header-title-dashwise {
  font-size: 80%;
  font-weight: 500;
  color: var(--bluelavender);
}

.deepdive-panel-header-title-finsights {
  font-size: 80%;
  font-weight: 500;
  color: var(--fnfontlight);
}

.deepdive-panel-header-amount-dashwise {
  font-size: 24px;
  font-weight: 700;
  color: var(--purplenavy);
  line-height: normal;
  margin-bottom: 6px;
}

.deepdive-panel-header-amount-finsights {
  font-size: 24px;
  font-weight: 700;
  color: var(--fnfontlight);
  line-height: normal;
  margin-bottom: 6px;
}

.deepdive-panel-content-dashwise {
  height: 27%;
}

.deepdive-panel-content-finsights{
  height: 27%;
  font-family: 'DM Sans', sans-serif !important;
  color: var(--fnblack);
}

.deepdive-panel-table-wrapper {
  height: 100%;
  overflow-y: auto;
  border-radius: 15px;
}

.deepdive-panel-table {
  position: relative;
  height: fit-content;
  background: var(--lighterwhite);
  padding: 15px 15px 5px 15px;
}

.deepdive-panel-table-list-item-wrapper {
  margin: 10px 0px 10px 0px;
}

.deepdive-panel-separation-wrapper {
  padding: 0px 20px 0px 20px;
}

.deepdive-panel-separation-line {
  height: 1px;
  border: 0.5px solid var(--platinumgray);
  padding: 0px 0px 0px 0px;
}

.deepdive-panel-table-sum-wrapper {
  position: sticky;
  bottom: 0;
  background: var(--lighterwhite);
  padding: 0 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.deepdive-panel-table-sum-dashwise {
  padding: 0.5rem 0.25rem 1rem 0.25rem;
  border-top: 1px solid var(--gray);
  background: var(--lighterwhite);
  font-size: 80%;
  font-weight: 700;
  text-align: right;
  color: var(--purplenavy);
}

.deepdive-panel-table-sum-finsights {
  padding: 0.5rem 0.25rem 1rem 0.25rem;
  border-top: 1px solid var(--gray);
  background: var(--lighterwhite);
  font-size: 80%;
  font-weight: 700;
  text-align: right;
  color: var(--fnfontlight);
}

.deepdive-panel-resetview-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
