.nav-menu-dropdown {
    top: unset;
    left: unset;
    width: unset;
    border-radius: unset;
    box-shadow: unset;
    padding: unset;
    margin-top: 2.875rem;
}

.nav-menu-title-dashwise {
    font-weight: 600;
}

.nav-menu-title-finsights {
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.nav-menu-lightturquoise-dashwise {
    background: transparent;
}

.nav-menu-lightturquoise-finsights {
    background: transparent;
}

.nav-menu-gray-dashwise {
    background: transparent;
}

.nav-menu-gray-finsights {
    background: transparent;
}

.nav-menu-lightorange-dashwise {
    background: transparent;
}

.nav-menu-lightorange-finsights {
    background: transparent;
}

.nav-menu-dropdown-transition {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.nav-menu-dropdown-label-dashwise {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    height: 3rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    position: sticky;
    top: 0;
    background: var(--lightergray);
}

.nav-menu-dropdown-label-finsights {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    height: 3rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    position: sticky;
    top: 0;
    background: #F4F1E9;
}

.nav-menu-dropdown-label-mediumturquoise-dashwise {
    background-color: var(--lightergray);
    color: var(--purplenavy);
}

.nav-menu-dropdown-label-mediumturquoise-finsights {
    background-color: transparent;
    color: var(--fnblack);
}

.nav-menu-dropdown-label-purplenavy {
    background-color: transparent;
    color: var(--purplenavy);
}

.nav-menu-dropdown-label-orange {
    color: var(--orange);
}

.nav-menu-dropdown-label>span:first-of-type {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 5%;
    width: 4px;
    height: 90%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.nav-menu-dropdown-label-purplenavy>span:first-of-type {
    background: var(--purplenavy);
}

.nav-menu-dropdown-label-mediumturquoise>span:first-of-type {
    background: var(--mediumturquoise);
}

.nav-menu-dropdown-label-icon-dashwise {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 14px;
    border: 1px solid #616D97;
    background: #F1F4F7;
    margin-right: 1rem;
}

.nav-menu-dropdown-label-icon-finsights {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 14px;
    border: 1px solid #D8D6CF;
    background: #F5F1E8;
    margin-right: 1rem;
}

.nav-menu-dropdown-label-arrow {
    display: inline-block;
    position: absolute;
    /* right: 1rem; */
    cursor: pointer;
}

.nav-menu-collapsed-show-options-dashwise {
    position: absolute;
    top: 255px;
    left: 80px;
    width: 300px;
    background: var(--lighterwhite);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}

.nav-menu-collapsed-show-options-finsights {
    position: absolute;
    top: 255px;
    left: 80px;
    width: 300px;
    background: var(--fnbeigemeddark);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}

.nav-menu-collapsed-show-options-clients-dashwise {
    position: absolute;
    top: 180px;
    left: 80px;
    width: 300px;
    background: var(--lighterwhite);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}

.nav-menu-collapsed-show-options-clients-finsights {
    position: absolute;
    top: 180px;
    left: 80px;
    width: 300px;
    background: var(--fnbeigemeddark);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}

.nav-menu-collapsed-show-options-admin-dashwise {
    position: absolute;
    top: 340px;
    left: 80px;
    width: 300px;
    background: var(--lighterwhite);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}

.nav-menu-collapsed-show-options-admin-finsights {
    position: absolute;
    top: 340px;
    left: 80px;
    width: 300px;
    background: var(--fnbeigemeddark);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}