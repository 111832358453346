.view-selector-views-view-dashwise {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--lighterwhite);
    transition: background 0.2s linear;
    font-weight: 500;
    color: var(--purplenavy);
    cursor: auto;
}

.view-selector-views-view-finsights {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--fnbeigemeddark);
    transition: background 0.2s linear;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    color: var(--fnblack);
    cursor: auto;
}

.view-selector-views-view-span-dashwise {
    color: var(--darkergray);
}

.view-selector-views-view-span-finsights {
    color: var(--fnblacklight);
}

.views-view-span-active-dashwise {
    color: var(--purplenavy)
}

.views-view-span-active-finsights {
    color: var(--fnblack);
}

.views-view-span-active-hide-columns-dashwise {
    color: var(--mediumturquoise)
}

.views-view-span-active-hide-columns-finsights {
    color: var(--fnorange)
}

.view-selector-views-view-arrow {
    transform: rotate(-90deg);
}

.view-selector-views-view-options-dashwise {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 18rem;
    top: 0;
    right: -18.25rem;
    background: var(--lighterwhite);
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 999;
}

.view-selector-views-view-options-finsights {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 18rem;
    top: 0;
    right: -18.25rem;
    background: var(--fnbeigemeddark);
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    font-family: 'DM Sans', sans-serif;
    z-index: 999;
}

.view-active-dashwise {
    background: var(--lightturquoise);
    cursor: pointer;
}

.view-active-finsights {
    background: var(--viewselectoroptionactive);
    cursor: pointer;
}
