
.login-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    background-color: var(--lightergray);
    height: 100vh;
}

.login-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: var(--white);
    border-radius: 16px;
    margin: 0 auto;
    width: 30rem;
    height: 15rem;
    padding: 2.5rem 0;
}

.login-with-section {
    display: flex;
    flex-direction: column;
}

.login-welcome {
    font-size: 28px;
    font-weight: 700;
    color: var(--purplenavy);
    text-align: center;
}

.login-logo {
    margin: 5rem 0;
}


