/* Nav  */

nav {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 312px;
  height: 100%;
}

.nav-dashwise {
  color: var(--purplenavy);
}

.nav-finsights {
  color: var(--fnblack);
}

.nav-dashwise>header {
  width: 100%;
  height: 92px;
  padding-left: 1rem;
  padding-top: 0.75rem;
}

.nav-finsights>header {
  width: 100%;
  height: 88px;
  padding-left: 1rem;
  padding-top: 0.25rem;
}

.nav-dashwise>footer {
  display: flex;
  align-items: center;
  width: var(--nav-width);
  height: 80px;
  position: fixed;
  bottom: 10px;
  left: 0px;
}

.nav-finsights>footer {
  display: flex;
  align-items: center;
  width: var(--nav-width);
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

ul {
  width: calc(var(--nav-width) - 21px + var(--padding));
  height: auto;
  list-style: none;
  background: white;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

ul.nav-header-dropdown-dashwise {
  position: absolute;
  z-index: 1000;
  top: 70px;
  left: 160px;
  width: 340px;
  padding: 1rem 2rem;
}

ul.nav-header-dropdown-finsights {
  position: absolute;
  z-index: 1000;
  top: 70px;
  left: 160px;
  width: 330px;
  padding: 1rem 2rem;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

ul.nav-header-dropdown-collapsed-dashwise {
  position: absolute;
  z-index: 1000;
  top: 70px;
  left: 80px;
  padding: 1rem 2rem;
}

ul.nav-header-dropdown-collapsed-finsights {
  position: absolute;
  z-index: 1000;
  top: 70px;
  left: 80px;
  padding: 1rem 2rem;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

.nav-footer-dropdown-dashwise {
  position: fixed;
  top: calc(100% - 80px - 80px + 5px);
  width: calc(var(--nav-width) - 80px + var(--padding));
  height: 80px;
  left: 160px;
  z-index: 100;
}

.nav-footer-dropdown-finsights {
  position: fixed;
  top: calc(100% - 80px - 80px + 5px);
  width: calc(var(--nav-width) - 80px + var(--padding));
  height: 80px;
  left: 160px;
  z-index: 100;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

.nav-footer-dropdown-collapsed-dashwise {
  position: fixed;
  top: calc(100% - 80px - 80px + 5px);
  width: calc(var(--nav-width) - 80px + var(--padding));
  height: 80px;
  left: 80px;
  z-index: 100;
  font-family: 'DM Sans', sans-serif !important;
}

.nav-footer-dropdown-collapsed-finsights {
  position: fixed;
  top: calc(100% - 80px - 80px + 5px);
  width: calc(var(--nav-width) - 80px + var(--padding));
  height: 80px;
  left: 50px;
  z-index: 100;
  background-color: var(--fnbeigemeddark);
}

nav>ul>li {
  font-size: 14px;
  padding: 5px 0px 5px 0px;
  user-select: none;
  font-weight: 500;
}

nav>ul>li:hover{
  font-weight:  700;
}

.nav-header-dropdown-item-text-dashwise {
  font-size: 14px;
}

.nav-header-dropdown-item-text-finsights {
  font-size: 14px;
  color: var(--fnblacklight);
}

.nav-header-dropdown-item-text-active-dashwise {
  font-size: 14px;
}

.nav-header-dropdown-item-text-active-finsights {
  font-size: 14px;
  color: var(--fnorange);
}

.nav-header-dropdown-item-icon {
  float: right;
}


/* Checkmark style */
.nav-header-dropdown-item-icon-dashwise>svg {
  width: 14px;
  height: 14px;
}

.nav-header-dropdown-item-icon-finsights>svg {
  width: 14px;
  height: 14px;
}

.nav-header-dropdown-item-icon-dashwise>svg>path{
  fill: var(--mediumturquoise);
}

.nav-header-dropdown-item-icon-finsights>svg>path {
  fill: var(--fnorange);
}

.nav-header-icon {
  height: 100%;
  width: 80px;
  float: left;
  display: flex;
  padding: 17.5px;
}


.nav-header-icon-container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.nav-header-icon-container-no-logo {
  height: 100%;
  width: 100%;
  background: var(--gray);
  border-radius: 10px;
}

.nav-header-icon-image {
  height: 90%;
  width: 90%;
  border-radius: 14px;
}

.nav-header-content {
  font-family: 'Montserrat';
  padding: 15px 0px;
  height: 100%;
  width: calc(100% - 80px - 15%);
  float: left;
}

.nav-header-button-dashwise {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-header-button-finsights {
  position: absolute;
  top: 4px;
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-header-button-container {
  height: 30px;
  width: 30px;
}

.nav-header-title-dashwise {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.nav-header-title-finsights {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: var(--fnblack);
}

.nav-header-subtitle-dashwise {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
}

.nav-header-subtitle-finsights {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 11px;
  font-weight: 500;
  color: var(--fnblacklight);
}

.nav-header-subtitle > span {
  margin-right: 5px;
}

.nav-footer-icon-dashwise {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 14px;
  border: 1px solid #616D97;
  background: #F1F4F7;
  margin-left: 2.2rem;
  margin-right: 1rem;
  z-index: 100;
}

.nav-footer-icon-finsights {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 14px;
  border: 1px solid #D8D6CF;
  background: #F5F1E8;
  margin-left: 2.2rem;
  margin-right: 1rem;
  z-index: 100;
}

.nav-footer-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.nav-footer-icon-container>img {
  width: 24px;
}

.nav-footer-content {
  font-family: 'Montserrat';
  padding: 15px 0px;
  height: 100%;
  width: calc(100% - 80px - 25%);
  float: left;
}

.nav-footer-button {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-footer-button-container {
  height: 30px;
  width: 30px;
}

.nav-footer-text-dashwise {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.nav-footer-text-finsights {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 14px;
  font-weight: bold;
  color: var(--fnblack);
}

.nav-clients-menu-container {
  margin-top: 1.875rem;
  /* height: calc(100% - 280px); */
  /* max-height: calc(100% - 400px); */
  overflow-y: scroll;
}

.nav-clients-menu-container>ul {
  margin-top: 0;
  margin-bottom: 0;
}

.nav-header-dropdown-item-consolidation {
  position: relative;
  border-top: 1px solid #D8DBE5;
  margin-top: 0.75rem;
  padding-top: 1rem;
}

.nav-header-dropdown-item-consolidation-icon {
  margin-right: 0.75rem; 
  vertical-align: sub;
}

.nav-header-dropdown-consolidation-sub-menu-dashwise {
  position: absolute;
    z-index: 1000;
    top: 0px;
    left: calc(var(--nav-width) - 50px + var(--padding));
    padding: 1rem 2rem;
}

.nav-header-dropdown-consolidation-sub-menu-finsights {
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: calc(var(--nav-width) - 50px + var(--padding));
  padding: 1rem 2rem;
  background-color: var(--fnbeigemeddark);
}

.nav-header-dropdown-consolidation-sub-menu-option {
  padding: 5px 0px 5px 0px;
}

.nav-header-dropdown-item-consolidation .nav-header-dropdown-item-text {
  font-weight: 500;
}

.nav-header-dropdown-item-consolidation .nav-header-dropdown-item-text:hover {
  font-weight: 700;
}
