.dropdown {
  margin: 0px 0px 0px 0px;
  padding-bottom: 20px;
  font-size: 13px;
  background-color: transparent;
}

.dropdown-label-dashwise {
  color: var(--bluelavender);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.dropdown-label-finsights {
  color: var(--fnblack);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.dropdown-label-inactive {
  color: var(--gray)
}

.dropdown-container-dashwise {
  border: 1.5px solid var(--graycloudy);
  border-radius: 15px;
  padding: 12px 16px;
  color: var(--main-text-color);
  background-color: var(--lighterwhite);
  font-weight: 500;
}

.dropdown-container-finsights {
  border: 1.5px solid rgba(50, 60, 68, 0.15);
  border-radius: 15px;
  padding: 12px 16px;
  color: var(--fnfontlight);
  background-color: var(--fnbeigelight);
  font-weight: 500;
}

.dropdown-container-inactive {
  border: 1.5px solid var(--gray);
  border-radius: 8px;
  padding: 4px;
  color: var(--gray);
}

.dropdown-container-inactive>div {
  cursor: auto;
}

.dropdown-button {
  display: flex;
  justify-content: space-between;
  cursor: auto !important;
}

.dropdown-asc {
  margin-right: 0.5rem;
}

.dropdown-desc {
  transform: rotate(180deg);
  margin-right: 0.5rem;
}

.dropdown-asc-small {
  margin-right: 0.5rem;
  height: 12px;
}

.dropdown-desc-small {
  height: 12px;
  transform: rotate(180deg);
  margin-right: 0.5rem;
}