.small-arrow-up {
    transform: rotate(-180deg);
}

.small-arrow-down {
    transform: rotate(0deg);
}

.small-arrow-right {
    transform: rotate(-90deg);
}

.small-arrow-left {
    transform: rotate(90deg);
}