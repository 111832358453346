.clients-table-wrapper {
    position: relative;
    padding: 1.5rem;
    height: 100%;
    width: 100%;
}

.clients-table-container {
    border: 1px solid var(--graycloudy);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.clients-table-row {
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid var(--graycloudy);
}

.clients-table-row-new {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--graycloudy);
}

.client-table-input-new {
    border: none;
    outline: none;
}

.clients-table-row-add {
    position: sticky;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--graycloudy);
    background-color: #fff;
    cursor: pointer;
}

.clients-table-add {
    padding: 1rem 1.25rem;
}

.clients-table-add > img {
    margin-right: 1rem;
}

.clients-table-row-column {
    font-weight: 700;
    background-color: var(--lighterwhite);
}

.clients-table-cell-0, .clients-table-cell-1, .clients-table-cell-2, .clients-table-cell-3 {
    padding: 1rem 1.25rem;
}

.clients-table-cell-0 {
    position: relative;
    display: flex;
    align-items: center;
    width: 320px;
    border-right: 1px solid var(--graycloudy);
}

.clients-table-cell-1 {
    display: flex;
    align-items: center;
    width: 80px;
    border-right: 1px solid var(--graycloudy);
}

.clients-table-cell-2 {
    display: flex;
    align-items: center;
    width: 330px;
    /* overflow: hidden;
    white-space: nowrap; */
    border-right: 1px solid var(--graycloudy);
}

.clients-table-cell-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 700px;
}

.clients-table-delete-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 150px;
    background-color: var(--lighterwhite);
    left: 0;
    right: 0;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    padding: 20px 20px;
    color: var(--purplenavy);
    font-weight: 500;
}

.clients-table-delete-modal > h5 {
    text-align: center;
}

.clients-table-delete-modal-btns {
    display: flex;
    justify-content: space-between;
}

.clients-table-delete-modal-btn {
    padding: 0px 32px;
    border: none;
    border-radius: 20px;
    color: var(--purplenavy);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 1rem;
    height: 30px;
    background-color: var(--graycloudy);
}

.clients-table-add-btn {
    padding: 0px 32px;
    border: none;
    border-radius: 20px;
    color: var(--purplenavy);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 1rem;
    height: 30px;
    width: 100px;
    background-color: var(--graycloudy);
}