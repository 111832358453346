/* Tile - wrapper for components */

.tile-wrapper {
    height: 100%;
    width: 100%;
    padding: var(--padding);
}

.tile-content {
    height: 100%;
    border-radius: 12px;
    position: relative;
    background: white;
}

.tile-content-deepdive-header {
    height: 100%;
    border-radius: 15px;
    position: relative;
    background: white;
    box-shadow: 0px 4px 20px 0px #F4F1E9;
}

.tile-content-deepdive {
    height: 100%;
    position: relative;
    background: transparent;
    border: 2px solid transparent !important;
}

.tile-content-fn {
    height: 100%;
    border-radius: 15px;
    position: relative;
    background: var(--fnbeige);
    border: 1px solid rgba(50, 60, 68, 0.15) !important;
}

.tile-content-view-fn {
    height: 100%;
    border-radius: 12px;
    position: relative;
    background: var(--fnbeigemeddark) !important;
    border: 1px solid rgba(50, 60, 68, 0.15) !important;
}

.tile-content-fn-selector {
    height: 100%;
    display: flex;
    position: relative;
    border: 1px solid transparent !important;
    font-family: 'DM Sans', sans-serif !important;
}

.tile-content-fn-metric-score {
    height: 100%;
    border-radius: 20px;
    position: relative;
    background: white;
    box-shadow: 0px 4px 20px 0px #F1EADB;
}

.tile-content-metric-score {
    height: 100%;
    border-radius: 20px;
    position: relative;
    background: white;
    box-shadow: 0px 4px 20px 0px rgba(58, 73, 125, 0.14);
}

.tile-button-back {
    background: var(--yellowsunshine);
    border: 1px solid var(--yellowsunshine) !important;
}

.tile-button-back-fn {
    background: var(--backbtn);
    border: 1px solid var(--backbtn) !important;
}

.tile-content-chart {
    height: 100%;
    border-radius: 0 12px 12px 12px;
    position: relative;
    background: white;
    box-shadow: 0px 4px 20px 0px rgba(156, 129, 104, 0.10);
}

.tile-content-table {
    height: 100%;
    border-radius: 0 12px 12px 12px;
    position: relative;
    background: white;
    box-shadow: 0px 4px 20px 0px rgba(156, 129, 104, 0.10);
}

.tile-content-admin {
    height: 100%;
    border-radius: 0 12px 12px 12px;
    position: relative;
    background: white;
    box-shadow: 0px 4px 20px 0px rgba(156, 129, 104, 0.10);
}

